import { graphql, useStaticQuery } from 'gatsby'

import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { MENU_LIST } from '../../const'
import MenuBlock from '../MenuBlock/MenuBlock'
import * as styles from './FoodMenu.module.scss'

const FoodMenu = () => {
  const data = useStaticQuery(graphql`
    {
      bottomLine: file(relativePath: { eq: "intro_dash.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <div className={styles.container}>
      <div className={styles.noteContainer}>
        [V] Vegetarian [Vg] Vegan [SF] Contains shellfish [S] Contains Soy [W]
        Contains wheat [L] Low-lactose
        <br />
        [G] Gluten free [G*] Gluten free on demand [SF] Chicken contains fish
        sauce [S] Tofu and Sauté Beef contain soy
        <br />
        <br />
        {`We use Finnish Top Round Beef (sisäpaisti)
        Extra Lean Cut (< 4% fat)`}
        <GatsbyImage
          image={data.bottomLine.childImageSharp.gatsbyImageData}
          className={styles.bottomLine}
        />
        <div className={styles.note}>
          To avoid food contamination, we do not have peanuts in our kitchen
          <br />
          Ask our staff about allergies if necessary
        </div>
      </div>
      {MENU_LIST.map((menu, i) => (
        <MenuBlock key={i} menu={menu} order={i % 4} />
      ))}
    </div>
  )
}

export default FoodMenu
