// extracted by mini-css-extract-plugin
export var container = "MenuUnitFood-module--container--zhqil";
export var date = "MenuUnitFood-module--date--zcIT0";
export var description = "MenuUnitFood-module--description--LCH-k";
export var hint = "MenuUnitFood-module--hint--7ttW6";
export var note = "MenuUnitFood-module--note--1SViq";
export var price = "MenuUnitFood-module--price--Qt+WW";
export var schedule = "MenuUnitFood-module--schedule--dIMuA";
export var slidedown = "MenuUnitFood-module--slidedown--e2plZ";
export var title = "MenuUnitFood-module--title--TI8H5";
export var unitPicture = "MenuUnitFood-module--unitPicture--vudHQ";