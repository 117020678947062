import { MENU_TYPES } from '../components/misc/MenuItem/MenuItem'
import FOODS from './food'

const starters = [
  {
    ...FOODS.SUMMER_ROLLS,
    description: '01 handmade rice paper roll, fresh veggies, mint [S]',
    price: '6',
    note: null,
    schedule: [
      {
        date: 'Toppings:',
        option: 'Smoked Salmon / Chicken / Tofu [Vg]',
      },
    ],
  },
  {
    ...FOODS.DUMPLING_DARLING,
    price: '6',
    description: '03 handmade dumplings, coriander, fried onions [W] [S]',
    schedule: [
      {
        date: 'Options:',
        option: 'Pork & Chicken / Vegan [Vg]',
      },
    ],
  },
  FOODS.FLYING_SHRIMP,
  FOODS.CHICKS_ON_STICKS,
  FOODS.WINGS,
  FOODS.TORPEDO_SHRIMP,
  FOODS.SAIGON_HIPSTER,
]

export const MENU_LIST = [
  // {
  //   name: MENU_TYPES.CAMPAIGN,
  //   list: [
  //     {
  //       name: 'Tapas Oasis',
  //       // price: '28',
  //       description:
  //         "The leisure moments are reserved for Be My Guest and Tapas Oasis - perfect for sharing! Signature Tapas Oasis includes Dumplings Darling, Charlie Don't Surf Salad, Always Summer Rolls, Chika Chika Wings, and Flying Shrimp",
  //       schedule: [
  //         {
  //           date: 'Available for pre-order, please reserve a table and let us know',
  //         },
  //       ],
  //       photoId: 'menuTapasOasis',
  //     },
  //   ],
  // },
  {
    name: MENU_TYPES.LUNCH,
    list: [],
  },
  {
    name: MENU_TYPES.TAPAS,
    list: [...starters, FOODS.PRAWN],
  },
  {
    name: MENU_TYPES.CLASSICS,
    listWithSections: [
      {
        name: 'Noodles',
        list: [
          FOODS.HANOI_ROCK_NOODLE,
          FOODS.COCO_NOODLES,
          FOODS.PHO,
          FOODS.NOODLES,
          FOODS.DUCK_NOODLE,
        ],
      },
      {
        name: 'Rice',
        list: [
          FOODS.HANOI_ROCK_RICE,
          FOODS.COCO_RICE,
          FOODS.DUCK_RICE,
          FOODS.CHIKA_RICE,
        ],
      },
      {
        name: 'Special handmade',
        list: [FOODS.BANHMI, FOODS.DUMPLING_DARLING, FOODS.SUMMER_ROLLS],
      },
    ],
  },
]
