import classnames from 'classnames/bind'
import React from 'react'
import MenuImg from '../MenuImg/MenuImg'
import MenuList from '../MenuList/MenuList'
import * as styles from './MenuBlock.module.scss'

let cx = classnames.bind(styles)

const MenuBlock = ({ menu, order }) => {
  return (
    <div
      id={menu.name.replace(/\s/g, '_').replace('!', '').toLowerCase()}
      className={cx(styles.container, {
        reversed: order % 2 !== 0,
      })}
    >
      <MenuImg
        className={styles.menuImgContainer}
        name={menu.name}
        header={menu.header}
        img={menu.img}
        order={order}
      />
      <MenuList
        list={menu.list}
        listWithSections={menu.listWithSections}
        menuName={menu.name}
        order={order}
      />
    </div>
  )
}

export default MenuBlock
