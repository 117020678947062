import React from 'react'
import DRINKS from '../../const/drinks'
import MenuBlock from '../MenuBlock/MenuBlock'
import { MENU_TYPES } from '../misc/MenuItem/MenuItem'
import * as styles from './DrinkMenu.module.scss'

const DrinkMenu = () => {
  const menuDrink = [
    {
      name: MENU_TYPES.DRINKS,
      list: [],
    },
  ]

  return (
    <div className={styles.container}>
      {menuDrink.map((menu, i) => (
        <MenuBlock key={i} menu={menu} order={1} />
      ))}
    </div>
  )
}

export default DrinkMenu
