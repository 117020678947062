import React from 'react'
import Price from '../misc/Price/Price'
import * as styles from './MenuUnitDrink.module.scss'

const MenuUnitDrink = ({ unit }) => {
  const { drink, options, isNew } = unit

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {drink}
        {isNew && <span className="blinkText">New!</span>}
      </div>
      <div>
        {options.map((option, i) => (
          <div key={`menuUnitDrink-${i}`} className={styles.option}>
            <span>{option.name}</span>
            <span className={styles.price}>
              <Price price={option.price} />
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MenuUnitDrink
