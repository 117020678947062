import 'react-slidedown/lib/slidedown.css'

import { GatsbyImage } from 'gatsby-plugin-image'
import * as React from 'react'

import { FaChevronDown } from 'react-icons/fa'
import Price from '../misc/Price/Price'
import * as styles from './MenuUnitFood.module.scss'

const MenuUnitFood = ({ unit, data }) => {
  const { name, description, price, note, schedule, photoId, altText, isNew } =
    unit

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {name}
        {isNew && <span className="blinkText">New!</span>}
        <span className={styles.price}>
          <Price price={price} />
        </span>
      </div>
      <div className={styles.description}>
        {description}

        <div className={styles.note}> {note} </div>
      </div>
      {schedule &&
        schedule.map((item, i) => (
          <div key={i} className={styles.schedule}>
            <span className={styles.date}>{item.date}</span>{' '}
            <span>{item.option}</span>
            {item.price && <Price price={item.price} />}
          </div>
        ))}
      {data[photoId] && (
        <div className={styles.hint}>
          <FaChevronDown />
        </div>
      )}
      <div className={styles.slidedown}>
        {data[photoId] && (
          <GatsbyImage
            image={data[photoId].childImageSharp.gatsbyImageData}
            alt={altText || ''}
            className={styles.unitPicture}
          />
        )}
      </div>
    </div>
  )
}

export default MenuUnitFood
