import classnames from 'classnames/bind'
import React from 'react'
import * as styles from './MenuImg.module.scss'

let cx = classnames.bind(styles)

const MenuImg = ({ name, header, order }) => {
  const menu0 = order === 0
  const menu1 = order === 1
  const menu2 = order === 2
  const menu3 = order === 3
  return (
    <div
      className={cx(styles.container, {
        menu0Container: menu0,
        menu1Container: menu1,
        menu2Container: menu2,
        menu3Container: menu3,
      })}
    >
      <div
        className={cx(styles.menuName, {
          menu0Name: menu0,
          menu1Name: menu1,
          menu2Name: menu2,
          menu3Name: menu3,
        })}
      >
        {header && <div className={styles.header}>{header}</div>}
        {name}
      </div>
    </div>
  )
}

export default MenuImg
