import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import * as styles from './MenuIntro.module.scss'

const MenuIntro = () => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => index + 1)

      return () => {
        clearInterval(interval)
      }
    }, 8000)
  }, [])

  const data = useStaticQuery(graphql`
    {
      carousel1: file(relativePath: { eq: "menu_slider1.png" }) {
        childImageSharp {
          gatsbyImageData(height: 400, quality: 30, layout: FULL_WIDTH)
        }
      }
      carousel2: file(relativePath: { eq: "menu_slider2.png" }) {
        childImageSharp {
          gatsbyImageData(height: 400, quality: 30, layout: FULL_WIDTH)
        }
      }
      carousel3: file(relativePath: { eq: "menu_slider3.png" }) {
        childImageSharp {
          gatsbyImageData(height: 400, quality: 30, layout: FULL_WIDTH)
        }
      }
    }
  `)

  const carouselImages = [
    data.carousel1.childImageSharp.gatsbyImageData,
    data.carousel2.childImageSharp.gatsbyImageData,
    data.carousel3.childImageSharp.gatsbyImageData,
  ]
  const currentIndex = index % carouselImages.length

  return (
    <div className={styles.container}>
      <GatsbyImage
        image={carouselImages[currentIndex]}
        className={styles.cover}
      />
      <div className={styles.sloganContainer}>
        <span className={styles.slogan}>
          Soft, crunchy, sweet, spicy - a party of contrasts.
        </span>
      </div>
    </div>
  )
}

export default MenuIntro
