import { graphql, useStaticQuery } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import MenuUnitDrink from '../MenuUnitDrink/MenuUnitDrink'
import MenuUnitFood from '../MenuUnitFood/MenuUnitFood'
import { MENU_TYPES } from '../misc/MenuItem/MenuItem'
import * as styles from './MenuList.module.scss'

const MenuList = ({ list, listWithSections, menuName }) => {
  // Query name must match photoId
  const data = useStaticQuery(graphql`
    {
      bottomLine: file(relativePath: { eq: "intro_dash.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      menuPho: file(relativePath: { eq: "menu_pho.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      menuBun: file(relativePath: { eq: "menu_bun.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      menuFresh: file(relativePath: { eq: "menu_fresh.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      menuBanhmi: file(relativePath: { eq: "menu_banhmi.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 30, layout: FULL_WIDTH)
        }
      }
      menuRolls: file(relativePath: { eq: "menu_rolls.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 30, layout: FULL_WIDTH)
        }
      }
      menuSaigon: file(relativePath: { eq: "menu_saigon.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 30, layout: FULL_WIDTH)
        }
      }
      menuWings: file(relativePath: { eq: "menu_wings.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 30, layout: FULL_WIDTH)
        }
      }
      menuCharlie: file(relativePath: { eq: "menu_charlie.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 30, layout: FULL_WIDTH)
        }
      }
      menuDumpling: file(relativePath: { eq: "menu_dumpling.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      menuShrimp: file(relativePath: { eq: "menu_shrimp.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      menuHanoi: file(relativePath: { eq: "menu_hanoi.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      menuTorpedo: file(relativePath: { eq: "menu_torpedo_shrimp.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      menuCocoRice: file(relativePath: { eq: "menu_coco_rice.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      menuCocoNoodle: file(relativePath: { eq: "menu_coco_noodle.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      menuDuckRice: file(relativePath: { eq: "menu_duck_rice.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      menuHanoiRice: file(relativePath: { eq: "menu_hanoi_rice.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      menuChikaRice: file(relativePath: { eq: "menu_chika_rice.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
    }
  `)

  const bottomLine = (
    <GatsbyImage
      image={data.bottomLine.childImageSharp.gatsbyImageData}
      className={styles.bottomLine}
    />
  )

  return (
    <div className={styles.container}>
      {menuName === MENU_TYPES.LUNCH && (
        <div className={styles.noteContainer}>
          <div className={styles.note}>
            We serve lunch on Monday - Friday 11-16. <br />
            Lunch menu can be found on{' '}
            <OutboundLink
              target="_blank"
              rel="noopener noreferrer"
              className={styles.address}
              href="https://www.facebook.com/bemyguest.fi/"
            >
              our Facebook
            </OutboundLink>
            .
            <br />
            Included in the lunch is salad, coffee and tea. Welcome!
            <br />
          </div>
          {bottomLine}
        </div>
      )}
      {menuName === MENU_TYPES.DRINKS && (
        <div className={styles.noteContainer}>
          <div className={styles.note}>
            You can find our drinks menu{' '}
            <OutboundLink
              target="_blank"
              rel="noopener noreferrer"
              className={styles.menu}
              href="https://drive.google.com/file/d/1OK6GbNFfjuScjCGQUo3Xh44nq1e7-OpP/view"
            >
              here
            </OutboundLink>
          </div>
          {bottomLine}
        </div>
      )}
      {list &&
        list.map((unit, i) => (
          <div key={`unitContainer-${i}`}>
            {menuName === MENU_TYPES.DRINKS ? (
              <MenuUnitDrink key={i} unit={unit} />
            ) : (
              <MenuUnitFood key={i} unit={unit} data={data} />
            )}
            {i !== list.length - 1 && bottomLine}
          </div>
        ))}
      {listWithSections &&
        listWithSections.map(({ name, list }) => (
          <div key={name}>
            <h2 className={styles.sectionTitle}>{name}</h2>
            {bottomLine}
            {list.map((unit, index) => (
              <>
                <MenuUnitFood
                  key={`sectionUnit-${index}`}
                  unit={unit}
                  data={data}
                />
                {index !== list.length - 1 && bottomLine}
              </>
            ))}
          </div>
        ))}
    </div>
  )
}

export default MenuList
