const FOODS = {
  PHO: {
    name: 'Phở Fighters',
    description: 'Famous Vietnamese Phở soup, onions, herbs',
    note: null,
    schedule: [
      {
        date: 'Option:',
        option: 'Beef',
        price: '16,5',
      },
      {
        date: 'Option:',
        option: 'Special Sauté Beef',
        price: '17,5',
      },
      {
        date: 'Options:',
        option: 'Chicken / Vegan [Vg]',
        price: '16',
      },
    ],
    altText: 'Authentic Pho in Jätkäsaari',
    photoId: 'menuPho',
  },
  NOODLES: {
    name: 'Noodle Around',
    price: '16',
    description:
      'Lukewarm bowl Viet noodle, fresh veggies, herbs, pickles, fried onions.',
    schedule: [
      {
        date: 'Options: ',
        option: 'Chicken [G*] / Tofu [Vg]',
      },
      {
        date: 'Option: ',
        option: 'Special Sauté Beef',
        price: '17,5',
      },
    ],
    altText: 'Healthy vietnamese food',
    photoId: 'menuBun',
  },
  BANHMI: {
    name: 'Coolest Bánh Mì on the Block',
    price: '11',
    description:
      'Authentic handmade Viet baguette, pickles, cucumber, coriander, sauce [W]',
    schedule: [
      {
        date: 'Options: ',
        option: 'Chicken / Tofu (V)',
        price: '11',
      },
      {
        date: 'Option: ',
        option: 'Sauté Beef',
        price: '12',
      },
      {
        date: 'Option: ',
        option: 'Roasted Duck',
        price: '13',
      },
    ],
    altText: 'Best Banh Mi in Helsinki',
    photoId: 'menuBanhmi',
  },
  SUMMER_ROLLS: {
    name: 'Always Summer Rolls',
    price: '17',
    description: '04 rice paper rolls with fresh veggies and mint ',
    note: 'Choose up to 2 toppings',
    schedule: [
      {
        date: 'Sauces: ',
        option: 'Sesame sauce [S] / "Nha Trang" green chili sauce [L]',
      },
      {
        date: 'Toppings: ',
        option: 'Smoked Salmon / Chicken / Tofu [Vg]',
        price: '17',
      },
    ],
    altText: 'Best Summer Rolls in Jätkäsaari',
    photoId: 'menuRolls',
  },
  SAIGON_HIPSTER: {
    name: 'Saigon Hipster',
    price: '6',
    description: 'Edamame beans in spicy sesame garlic sauce. [G] [Vg]',
    note: null,
    schedule: null,
    altText: 'Best restaurant in Jätkäsaari',
    photoId: 'menuSaigon',
  },
  PRAWN: {
    name: 'Prawn Crackers [G]',
    price: '3',
    description: null,
    note: null,
    schedule: null,
  },
  RICE_GREENS: {
    name: 'Steamed Rice',
    price: '2',
    note: null,
    schedule: null,
  },
  DUMPLING_DARLING: {
    name: 'Dumplings Darling',
    price: '12,5',
    description: '8 handmade dumplings, coriander, fried onions [W] [S]',
    schedule: [
      {
        date: 'Options: ',
        option: 'Pork & Chicken / Vegan (Vg)',
      },
    ],
    altText: 'Authentic dumpling in Helsinki',
    photoId: 'menuDumpling',
  },
  CHARLIE: {
    name: "Charlie Don't Surf Salad",
    price: '6,5',
    description:
      'Crunchy gỏi salad, cabbage, carrot, pickled onions, mint, vegan fish sauce [G*]',
    schedule: [
      {
        date: 'Options:',
        option: 'Shrimp, tofu [VG] [Soy].',
      },
    ],
    photoId: 'menuCharlie',
  },
  HANOI_ROCK_NOODLE: {
    name: "Hanoi Rock'n'Noodle",
    description:
      'Special Hanoi style noodle with thick tomato sauce, cabbage, leek, herbs [G*]',
    schedule: [
      {
        date: 'Options:',
        option: 'Chicken / Tofu [Vg]',
        price: '18',
      },
      {
        date: 'Option:',
        option: 'Sauté Beef',
        price: '19',
      },
    ],
    photoId: 'menuHanoi',
  },
  HANOI_ROCK_RICE: {
    name: "Hanoi Rock'n'Rice",
    description:
      'Special Hanoi style rice with thick tomato sauce, cabbage, leek, herbs [G*]',
    schedule: [
      {
        date: 'Options:',
        option: 'Chicken / Tofu [Vg]',
        price: '18',
      },
      {
        date: 'Option:',
        option: 'Sauté Beef',
        price: '19',
      },
    ],
    photoId: 'menuHanoiRice',
  },
  OCEAN_VIBE: {
    name: 'Ocean Vibe',
    price: '19',
    description:
      'Seafood stew (shrimp, squid), tomato, lemon grass, lime leaves [G*] [Soy]. Served with rice or noodles.',
  },
  WINGS: {
    name: 'Chika Chika Wings (6/10/20pcs)',
    price: '7 / 11 / 18',
    description:
      "Sweet'n'sour spicy chicken wings, fish sauce, garlic, ginger [G] [SF]",
    photoId: 'menuWings',
    altText: 'wings ravintola',
  },
  FLYING_SHRIMP: {
    name: 'Flying Shrimp',
    price: '9',
    description:
      'Grilled Tiger prawn skewers, lemongrass chili sauce served with special "Nha Trang" green chili sauce [G] [L] [SF]',
    photoId: 'menuShrimp',
  },
  CHICKS_ON_STICKS: {
    name: 'Chicks On Sticks',
    price: '7',
    description:
      'Grilled chicken skewers, lemongrass chili sauce served with tamarind lime dressing [G] [SF*]',
    isNew: true,
  },
  TORPEDO_SHRIMP: {
    name: 'Torpedo Shrimp',
    price: '6',
    description: 'Breaded shrimp sticks, salad, chili mayo',
    photoId: 'menuTorpedo',
  },
  COCO_NOODLES: {
    name: 'Coco Noodle',
    description: 'Creamy coconut broth, eggplant, bean cress, herbs [G]',
    schedule: [
      {
        date: 'Options:',
        option: 'Chicken / Tofu [Vg]',
        price: '16',
      },
      {
        date: 'Option:',
        option: 'Shrimp',
        price: '17',
      },
    ],
    photoId: 'menuCocoNoodle',
  },
  COCO_RICE: {
    name: 'Coco Rice',
    description: 'Creamy coconut sauce, eggplant, sweet potato, herbs [G]',
    schedule: [
      {
        date: 'Options:',
        option: 'Chicken / Vegan [Vg]',
        price: '16',
      },
      {
        date: 'Option:',
        option: 'Shrimp',
        price: '17',
      },
    ],
    photoId: 'menuCocoRice',
  },
  DUCK_NOODLE: {
    isNew: true,
    name: 'Special Noodle Around',
    description:
      'Special Noodle Around with roasted duck & crunchy spring rolls (crab & shrimp stuffing) [S] [G*]',
    price: '18',
  },
  DUCK_RICE: {
    name: 'Duckylicious Rice',
    description:
      'Crunchy roasted duck, scallion oil, salad, pickles, soy ginger sauce [S]',
    price: '17',
    photoId: 'menuDuckRice',
  },
  CHIKA_RICE: {
    name: 'Chika Wings Rice',
    description:
      'Spicy chicken wings glazed with fish sauce, salad, pickles [G] [SF]',
    price: '14',
    photoId: 'menuChikaRice',
  },
}

export default FOODS
