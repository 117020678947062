import classnames from 'classnames/bind'
import React from 'react'
import * as styles from './MenuItem.module.scss'

let cx = classnames.bind(styles)

export const MENU_TYPES = {
  TAPAS: 'STARTER',
  CAMPAIGN: 'SPECIAL OFFER',
  LUNCH: 'LUNCH',
  CLASSICS: 'MAINS',
  DRINKS: 'DRINKS',
}

const MenuItem = ({ menuType = MENU_TYPES.DINNER }) => {
  const menuValue =
    Object.values(MENU_TYPES).findIndex((v) => v === menuType) % 4
  return (
    <div
      className={cx(styles.menuItemContainer, {
        menuItem0: menuValue === 0,
        menuItem1: menuValue === 1,
        menuItem2: menuValue === 2,
        menuItem3: menuValue === 3,
      })}
    >
      {menuType}
    </div>
  )
}

export default MenuItem
