import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import DrinkMenu from "../components/DrinkMenu/DrinkMenu"
import FoodMenu from "../components/FoodMenu/FoodMenu"
import Footer from "../components/Footer/Footer"
import Layout from "../components/Layout/Layout"
import MenuIntro from "../components/MenuIntro/MenuIntro"
import ContentLayout from "../components/misc/ContentLayout/ContentLayout"
import NavbarContainer from "../components/misc/NavbarContainer/NavbarContainer"

const MenuPage = () => {
  return (
    <ParallaxProvider>
      <Layout
        title="Be My Guest | Vietnamese Restaurant | Authentic Cuisine"
        description="Be My Guest – Vietnamese Restaurant in Jätkäsaari, Helsinki offers a
deeper dive into AUTHENTIC Vietnamese food culture. EXTRAORDINARY Pho &amp;
Summer Rolls"
      >
        <NavbarContainer dark />
        <MenuIntro />
        <ContentLayout>
          <FoodMenu />
          <DrinkMenu />
        </ContentLayout>
        <Footer />
      </Layout>
    </ParallaxProvider>
  )
}

export default MenuPage
