// extracted by mini-css-extract-plugin
export var container = "MenuImg-module--container--x1E-k";
export var header = "MenuImg-module--header--5D34L";
export var menu0Container = "MenuImg-module--menu0Container--mt36h";
export var menu0Name = "MenuImg-module--menu0Name--MTKhU";
export var menu1Container = "MenuImg-module--menu1Container--YjTRn";
export var menu1Name = "MenuImg-module--menu1Name--F4tQc";
export var menu2Container = "MenuImg-module--menu2Container--UR0BP";
export var menu2Name = "MenuImg-module--menu2Name--9vko3";
export var menu3Container = "MenuImg-module--menu3Container--+3Tom";
export var menu3Name = "MenuImg-module--menu3Name--xocTv";
export var menuName = "MenuImg-module--menuName--yzVTu";